.widget-subtitle {
    color: #8e8e8e;
    font-size: 80%;
    font-weight: 400;
    margin-bottom: 15px
}

.widget-subtitle b {
    color: #000000;
}

.widget-body p {
    color: #8e8e8e;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0;
    margin-bottom: 1rem;
    /* width:266 */
    height: 88px;
}

.widget-title{
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.125rem;
    margin: 0 0 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}

.widget {
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    padding: 20px;
    position: relative;
}

.widget-subbody{
    margin-bottom: 15px;
}

.widget-subbody-header {
    /* font-family: 'CircularStd', sans-serif;
    font-size: .9375rem; */
    color: #1f1f1f;
    font-weight: 400;
    line-height: 1.5;
    box-sizing: border-box;
}

.widget-subbody-text{
    color: #8e8e8e;
    line-height: 1.5;
}

.widget-subbody-space{
    color: #8e8e8e;
    line-height: 1.5;
    min-height: 30px;
}