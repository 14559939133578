button {
      background: none;
      border: none;
}
.payroll-input {
      width: 80px;
      border: none;
      background-color: transparent;
}

.payroll-input:focus {
      border: 1px solid #ced4da;
}

.loader {   
      position: absolute;
      background-color: white;
      height: 100%;
      width: 100%;
      max-height: 60vh;
      overflow: hidden;
      z-index: 99;
}

.rdtPicker {
      top: 0 !important;
      right: 0 !important;
}

