.modal-content {
}

.mdby {
  height: 400px !important;
  padding-top: 20px;
}

.content-2 {
  display: flex;
  flex-direction: column;
}

.st-no {
  text-align: center;
  font-size: 18px;
}

.wn-no {
  font-size: 13px;
  margin-bottom: 40px;
}

.sn-q {
  text-align: center;
}

.input-field {
  border: 1px solid #ccc;
  height: 35px;
  border-radius: 3px;
  padding: 3px 7px;
  text-align: center;
}

.in-one {
  margin-bottom: 20px;
}

.cn-btn {
  display: block;
  background-color: #0071e0;
  text-align: center;
  color: #fff;
  margin-top: 35px;
  border-radius: 3px;
  padding: 12px 0;
  cursor: default;
}

.ds-btn{
  background-color: #ccc !important;
  pointer-events: none;
}
